.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.containerInner {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #000;
  width: 100%;
  height: 100%;
  padding: 0 0 30px 0;
}

.navigationContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  box-sizing: border-box;
  background: #000;
  width: 100%;
  height: 80px;
  padding: 25px 45px 0 45px;
}

.navigationContainer div {
  margin-left: 40px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  box-sizing: border-box;
  background: #000;
  width: 100%;
  height: 100%;
  padding: 7% 15px 45px 15px;
}

.containerCta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.containerCta button {
  margin: 20px 20px 20px 20px;
}

.welcomeMessage {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  width: 100%;
  height: auto;
  font-size: 28px;
}

.artwork {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 250px;
}

.artwork img {
  width: auto;
  height: auto;
  align-self: center;
  max-height: 100%;
  max-width: 500px;
  user-select: none;
}

/* 
* Buttons
*/

.buttonPlayMode {
  text-align: center;
  font-size: 18px;
  width: 140px;
  height: 140px;
  margin: 20px 0 0 0;
  padding: 24px 24px 28px 24px;
  background: #fd5750;
  color: #fff;
  font-family: "Titillium Web", sans-serif;
  font-size: 24px;
  font-weight: 600;
  border-radius: 4px;
  border: 0px;
  transition: all 0.14s ease;
  user-select: none;
  outline: none;
  text-transform: lowercase;
}

.buttonPlayMode:disabled,
.buttonPlayMode[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.buttonPlayMode:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.buttonPlayMode:active {
  cursor: pointer;
  transform: scale(1);
  outline: none;
}

/**
 * Special
 */

::-moz-selection {
  background: #fd5750;
  color: #ffffff;
  opacity: 1;
}

::selection {
  background: #fd5750;
  color: #ffffff;
  opacity: 1;
}

.heroArtwork {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.heroArtwork img {
  width: auto;
  height: auto;
  align-self: center;
  max-height: 100%;
  max-width: 500px;
  user-select: none;
}
