.navigationContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  box-sizing: border-box;
  background: #000;
  width: 100%;
  height: 80px;
  padding: 25px 45px 0 45px;
}

.navigationContainer div {
  margin-left: 40px;
}
